/* Utility classes */
.commonheadingDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0px;

  .commonheadingbgtxt {
    transition: none;
    text-wrap: nowrap;
    position: absolute;
    font-size: 14vmin;
    font-weight: 600;
    color: #e1e1e1;
    letter-spacing: 5px;
  }
  .commonheading {
    z-index: 1;
    font-weight: 600;
    color: #111;
  }
}
.commonheadingbgtxt {
  transition: transform 0.3s ease-out;
}
.greenBorder {
  width: 70px;
  border-bottom: 3px solid #20c997;
  z-index: 2;
}
/* Utility classes */

.fillRange {
  text-align: right;
  padding: 10px 0px;
  font-weight: bold !important;
}

.counterParent {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .counterChild {
    color: rgb(69, 69, 69);
    padding: 30px 0px;
    width: 100%;
    text-align: center;
    border-right: 1px solid rgb(218, 218, 218);

    h4 {
      font-size: 50px;
      font-weight: 600;
    }

    p {
      font-size: 20px !important;
    }
  }
  .counterChild:last-child {
    border: none;
  }
}
.active {
  background-color: #004526;
}

/* side bar css STARTS here */
.hamBtnDiv {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.sideBarMain {
  background-color: #111418 !important;
  color: white;
  height: 100%;
  /* min-height: 100vh;
  max-height: 100vh; */
}
.MyName {
  font-size: 3vmin;
}
.scrollNone {
  border-right: none !important;
  & ::-webkit-scrollbar {
    display: none !important;
  }
}

.scrollNone div {
  border-right: none !important;
}
.sideBarContent {
  border-radius: 5px !important;
  transition: 0.5s ease-in-out;

  &:hover {
    background-color: #004526 !important;
  }
}
.profileImgDiv {
  margin-bottom: 20px !important;
  width: 70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilePhoto {
  border: 10px solid #343a40;
  border-radius: 50%;
  width: 100%;
}
/* side bar css ENDS here */

/* home START here */
.homeMain {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  ) !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .homeMainBg {
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }

  .homeMainContent {
    padding: 20px;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    h2 {
      font-weight: 500;
      font-size: 30px;
    }
    .myContent {
      animation-delay: 0.2s;
      font-size: 8vmin;
      font-weight: 700;
    }
    p {
      animation-delay: 0.3s;
      font-size: 22px;
    }
  }
}
/* home ENDS here */

/* about STARTS here */
.aboutMain {
  overflow: hidden;
  height: 100%;
  /* padding: 10px; */

  .aboutContentDiv {
    padding: 20px 40px;
    display: flex;

    .aboutContentLeft {
      min-width: 300px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      color: rgb(69, 69, 69);

      h2 {
        font-weight: 600;
        font-size: 27px;
        color: rgb(47, 47, 47);
      }

      h2,
      p {
        letter-spacing: 0.5px;
        line-height: 28px;
        padding: 15px 0px;
      }
      p {
        padding: 5px;
      }
    }

    .aboutContentRight {
      padding: 0px 20px;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      /* gap: 20px; */

      p {
        font-size: 15px;
        color: rgb(69, 69, 69);
        padding: 15px 0px;
        border-bottom: 1px solid rgb(218, 218, 218);
      }
      p:nth-child(4) {
        border: none;
      }
    }
  }
}
/* about ENDS here */

/* WhatIDoMain STARTS here */
.WhatIDoMain {
  overflow: hidden;
  background-color: #f8f9fa;
  color: rgb(43, 43, 43);
  height: 100%;
  padding-bottom: 100px;

  .WhatIDoContent {
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    .WhatIDoContentCommon {
      margin: 20px 0px;
      max-width: 550px;
      /* border: 1px solid black; */
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 45%;

      .whatIDoIconDiv {
        min-width: 70px;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgb(216, 216, 216);

        .whatIDoIcon {
          font-size: 35px;
        }
      }
      .whatIDoContentSub {
        h1 {
          font-weight: 600;
          font-size: 23px;
        }
        p {
          font-size: 15px;
          line-height: 25px;
          letter-spacing: 0.5px;
          text-align: justify;
        }
      }
    }
  }
}
/* WhatIDoMain ENDS here */

/* Resume STARTS here */
.resumeMain {
  overflow: hidden;
  height: 100%;

  .resumeContentDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 20px 50px;
  }

  .myExperience,
  .myEducation {
    min-width: 300px;
    max-width: 600px;
    width: 48%;
    color: rgb(43, 43, 43);

    .myEducationChild {
      border-radius: 5px;
      margin: 20px 0px;
      border: 1px solid rgb(227, 227, 227);
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      span {
        font-size: 15px;
        color: white;
        background-color: #20c997;
        width: fit-content;
        padding: 3px 5px;
        border-radius: 5px;
      }
      .clgLoca {
        color: #dc3545;
      }
      .feildDetail {
        text-align: justify;
      }
    }
  }
  .mySkill {
    padding: 20px 50px;
    color: rgb(43, 43, 43);

    h2 {
      padding-left: 20px;
    }
    .mySkillChilds {
      display: flex;
      /* justify-content: center; */

      flex-wrap: wrap;
      .mySkillChild {
        padding: 20px;
        width: 50%;
        min-width: 300px;

        .mySkillChildHeading {
          font-weight: 600;
          margin-bottom: -34px;
        }
      }
    }
  }
  .resumeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* Resume ENDS here */

/* Portfolio STARTS here */
.portfolioMain {
  padding-bottom: 50px;
  overflow: hidden;
  height: 100%;
  background-color: #f8f9fa;
}
/* Portfolio ENDS here */

/* Testimonial STARTS here */
.testimonialMain {
  height: 100%;
  padding-bottom: 100px;
  overflow: hidden;

  .sliderParent {
    width: 100%;
    /* padding: 30px; */
    /* border: 1px solid black; */
  }
}
/* Testimonial ENDS here */

/* Contact STARTS here */
.contactMain {
  overflow: hidden;
  height: 100%;
  background-color: #f8f9fa;
  color: rgb(43, 43, 43);

  .ContactBody {
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    gap: 30px;

    .ContactBodyLeft {
      min-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      line-height: 30px;

      h3 {
        font-size: 23px;
        font-weight: 500;
      }

      .ContactSubDiv i {
        margin-right: 10px;
      }
      .socialMediaDiv {
        display: flex;
        gap: 20px;
        /* cursor: pointer; */
        font-size: 17px;

        i {
          color: rgb(63, 63, 63);
          transition: 0.2s;
        }
        i:hover {
          color: #20c997;
        }
      }
    }
    .ContactBodyRight {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .ContactRightSub {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        height: 100%;

        form {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .ContactRightInput {
            input {
              padding: 18px;
              width: 100% !important;
              border-radius: 5px;
              border: 1px solid #ddd;
              background-color: white;
            }
            input::placeholder {
              color: #bbb;
              font-size: 15px;
              font-family: "Poppins", sans-serif !important;
            }
            textarea {
              width: 100%;
            }
          }
          .ContactRightBtnDiv {
            display: flex;
            align-items: center;
            justify-content: end;
            button {
              background-color: #fff;
              color: #20c997;
              border: 2px solid #20c997;
              /* borde */
            }
          }
        }
        textarea {
          padding: 18px;
          border-radius: 5px;
          border: 1px solid #ddd;
          background-color: white;
        }
        textarea::placeholder {
          color: #bbb;
          font-size: 15px;
          font-family: "Poppins", sans-serif !important;
        }
        .cvBtnDiv {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .footer2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 50px 30px;

    p span {
      letter-spacing: 1px;
      font-weight: bold;
      color: #20c997;
    }
  }
}
/* Contact ENDS here */

/* Media Queries starts here */
@media only screen and (max-width: 1000px) {
  .aboutContentDiv {
    padding: 8px !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .aboutContentLeft {
    padding-right: 0 !important;
  }
  .aboutContentLeft P {
    text-align: justify;
  }
  .aboutContentRight {
    margin-top: 20px;
    padding: 0px !important;
  }
  .counterParent {
    flex-wrap: wrap;
  }
  .aboutContentRight {
    min-width: 250px !important;
  }
  .abtMyDetail {
    text-align: center;
  }
  .cbBtnLink {
    margin: 20px auto !important;
  }
  .WhatIDoMain {
    height: 100% !important;
  }
  .WhatIDoContent {
    padding: 18px !important;
  }
  .WhatIDoContentCommon {
    flex-direction: column;
    text-align: center;
    min-width: 280px;
  }
  .myEducation,
  .myExperience {
    min-width: 280px !important;
    width: auto !important;
  }
  .resumeContentDiv {
    padding: 10px !important;
  }
  .ContactBody {
    padding: 20px !important;

    flex-wrap: wrap;
    text-align: center;
  }
  .ContactBodyRight {
    margin-top: 30px;
  }
  .socialMediaDiv {
    justify-content: center;
  }
  .mySkill {
    padding: 0px !important;
    margin: 0 auto;
    width: 100% !important;
    max-width: 600px;
  }
  .mySkillChild {
    padding: 20px 10px !important;
    width: 100% !important;
  }
  .commonheadingbgtxt {
    font-size: 10.5vmin !important;
  }
  .portfolioMain {
    height: 70vh;
  }
}

@media only screen and (max-width: 500px) {
  .commonheadingDiv {
    padding: 50px 0px;
  }
  .ContactRightInput {
    flex-direction: column;

    & input {
      width: 100% !important;
    }
  }
  .ContactBodyLeft {
    min-width: 250px !important;
  }
  .ContactBody {
    padding: 10px !important;
  }
  .footer2 {
    flex-direction: column;

    & p {
      text-align: center;
      margin: 5px 0px;
    }
  }
  .ContactSubDiv {
    & p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .myExperience,
  .myEducation {
    text-align: center;
  }
  .myEducationChild {
    & span {
      margin: 0 auto;
    }
  }
  .socialMediaDiv {
    gap: 30px !important;
    i {
      font-size: 25px;
    }
  }
}
/* Media Queries ends here */

.inside-image img {
  transition: 0.3s;
  /* background-color: red; */
  border: 1px solid red;
}

.inside-image {
  transition: 0.3s;
}
