/* .carousel .carousel-item {
  color: #999;
  overflow: hidden;
  min-height: 120px;
  font-size: 13px;
}
.carousel .media {
  position: relative;
  padding: 0 0 0 20px;
}
.carousel .media img {
  width: 75px;
  height: 75px;
  display: block;
  border-radius: 50%;
}
.carousel .testimonial-wrapper {
  padding: 0 10px;
}
.carousel .testimonial {
  color: #808080;
  position: relative;
  padding: 15px;
  background: #f1f1f1;
  border: 1px solid #efefef;
  border-radius: 3px;
  margin-bottom: 15px;
}
.carousel .testimonial::after {
  content: "";
  width: 15px;
  height: 15px;
  display: block;
  background: #f1f1f1;
  border: 1px solid #efefef;
  border-width: 0 0 1px 1px;
  position: absolute;
  bottom: -8px;
  left: 46px;
  transform: rotateZ(-46deg);
}
.carousel .star-rating li {
  padding: 0 2px;
}
.carousel .star-rating i {
  font-size: 16px;
  color: #ffdc12;
}
.carousel .overview {
  padding: 3px 0 0 15px;
}
.carousel .overview .details {
  padding: 5px 0 8px;
}
.carousel .overview b {
  text-transform: uppercase;
  color: #1abc9c;
}
.carousel .carousel-indicators {
  bottom: -70px;
}
.carousel-indicators li,
.carousel-indicators li.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 1px 2px;
  box-sizing: border-box;
}
.carousel-indicators li {
  background: #e2e2e2;
  border: 4px solid #fff;
}
.carousel-indicators li.active {
  color: #fff;
  background: #1abc9c;
  border: 5px double;
} */

/* Importing fonts from Google */

/*@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

 Reseting 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background: linear-gradient(to right, #101c81, #2a6ba3);
  min-height: 100vh;
}
*/
.owl-carousel .owl-item {
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item .card {
  padding: 30px;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  overflow-y: auto !important;
  padding-bottom: 40px;
}

.owl-carousel .owl-item img {
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}

.owl-carousel .owl-item .card .name {
  position: absolute;
  bottom: -20px;
  left: 33%;
  color: #101c81;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: aquamarine;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  box-shadow: 2px 3px 15px #3c405a;
}

.owl-carousel .owl-item .card {
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item.active.center .card {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-theme .owl-dots .owl-dot span {
  height: 20px;
  background: #2a6ba3 !important;
  border-radius: 2px !important;
  opacity: 0.8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 13px;
  width: 13px;
  opacity: 1;
  transform: translateY(2px);
  background: #83b8e7 !important;
}

@media (min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
    left: 24%;
  }
}

@media (max-width: 360px) {
  .owl-carousel .owl-item .card .name {
    left: 30%;
  }
}

.slider-parent {
  margin: 0 auto;
  width: 80vw;
  height: auto;
  cursor: pointer;
  /* background-color: red; */
}
.sliderChilds {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 30px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 300px;

  .sliderChildsHeading {
    display: flex;
    align-items: center;
    gap: 20px;

    .sliderChildsHeadingImg {
      width: 60px !important;
      border-radius: 50%;
    }
  }
  .sliderChildsBody {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
    height: 100%;

    .clientFeedback {
      text-align: justify;
    }
  }
}

@media screen and (max-width: 480px) {
  .slider-parent {
    width: 83vw;
  }
  .sliderChilds {
    padding: 15px;
  }
}
