/* :root {
  --primary-color: slategrey ;
} */

/* * {
  box-sizing: border-box ;
} */

.sliderParent {
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-container {
  border-radius: 30px;
  overflow: hidden !important;
  max-width: 800px;
  position: relative;
  box-shadow: 0 0 30px -20px #223344;
  margin: auto;
  z-index: 0;
  width: 95%;
  height: auto;
}

/* Hide the images by default */
.mySlides {
  transition: 0.3s !important;

  a {
    user-select: none !important;
    -webkit-user-select: none !important;
  }
  /* display: none; */
}
.mySlides img {
  user-select: none !important;
  -webkit-user-select: none !important;
  transition: 0.3s !important;
  display: block;
  width: 100%;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: auto;
  padding: 20px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  border-radius: 0 8px 8px 0;
  background: rgba(70, 70, 70, 0.271);
  user-select: none;
}
.next {
  right: 0;
  border-radius: 8px 0 0 8px;
}
.prev:hover,
.next:hover {
  background-color: rgba(57, 57, 57, 0.287);
}
.prev:active,
.next:active {
  background-color: rgba(57, 57, 57, 0.395);
}

/* Caption text */
.slidetext {
  color: #f2f2f2;
  background-color: rgba(28, 28, 28, 0.266);
  backdrop-filter: blur(6px);
  border-radius: 10px;
  font-size: 20px;
  padding: 8px 12px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
}

/* Number text (1/3 etc) */
.number {
  color: #f2f2f2;
  font-size: 16px;
  background-color: rgba(173, 216, 230, 0.15);
  backdrop-filter: blur(6px);
  border-radius: 10px;
  padding: 8px 12px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.dots-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}

/* The dots/bullets/indicators */
.dots {
  cursor: pointer;
  height: 14px;
  width: 14px;
  margin: 0 4px;
  background-color: rgba(173, 216, 230, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
}
.active,
.dots:hover {
  background-color: rgba(173, 216, 230, 0.8);
}

/* transition animation */
.animate {
  -webkit-animation-name: animate;
  -webkit-animation-duration: 1s;
  animation-name: animate;
  animation-duration: 2s;
}

@keyframes animate {
  from {
    transform: scale(1.1) rotateY(10deg);
  }
  to {
    transform: scale(1) rotateY(0deg);
  }
}

@media screen and (max-width: 700px) {
  .carousel-container {
    border-radius: 15px !important;
  }
  .prev,
  .next {
    padding: 10px;
    font-size: 20px;
  }
  .slidetext {
    padding: 4px 12px;
    font-size: 18px;
    color: #6e6e6e;
  }
}
